import React, { useState, useEffect } from "react";
import MissionVisionValues from "./MissionVisionValues";
import OurAchievements from "./Our Achievements";
import Affiliations from "./Affiliations";
import OurContribution from "./OurContribution";
import CountUp from "react-countup";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
// import founderPic from "../../../../Images/Aboutus-pics/IMG_3225.jpg";
// import ceoPic from "../../../../Images/Aboutus-pics/CEO-PIC.jpg";
import award1 from "../../../../Images/Aboutus-pics/women0f2023.jpg";
// import award3 from "../../../../Images/Gallery/img-felicitation.jpg";
import award3 from "../../../../Images/Gallery/img-felicitation.jpg";
import APERCLogo from "../../../../Images/Common/aperc-logo.jpg";
import { Blurhash } from "react-blurhash";
import "../../../../CSS/About.css";
import "../../../../CSS/main.css";
import MetaTags from "../../../MetaInfo/MetaTags";
import pdf from "../../../../Assets/Gazette.pdf";
import newImg from "../../../../Images/Common/new-img-updates.gif";

const OurOrganisation = () => {
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [imgLoading, setImgLoading] = useState({
    award1: false,
    award3: false,
  });

  const divStyle = {
    color: "#183b56",
  };
  const openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleOtherToggle = () => {
    setIsCollapse(!isCollapse);
  };
  const formatter = (value) => (
    <div>
      <CountUp end={value} separator="," />
      <sup> +</sup>
    </div>
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const images = [
      { src: award1, key: "award1" },
      { src: award3, key: "award3" },
    ];
    images.forEach(({ src, key }) => {
      const img = new Image();
      img.onload = () => {
        setImgLoading((prev) => ({
          ...prev,
          [key]: true,
        }));
      };
      img.src = src;
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      {" "}
      <MetaTags
        title={"Skill Training for Unemployed Youth in Telangana | DDU-GKY"}
        Description={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood."
        }
        Keywords={
          "Skill training Telangana Unemployed youth training DDU-GKY Telangana NDES skill development Youth livelihood programs"
        }
        href={"https://ndskills.in/ourOrganisation"}
      />
      <div className=" overflow-hidden">
        <div className="m-4 d-flex gap-2 flex-column">
          <div className="about-heading text-center">
            <h1>
              NEW DIRECTIONS EDUCATIONAL SOCIETY (NDES)
              <br />
              WE EMPOWER LIVES
            </h1>
          </div>
          <p className="pb-2  text-justify">
            Our mission is to guide students, underprivileged individuals, and
            unemployed youth towards new directions and opportunities. Founded
            by Shri Dasarath Ram Beegala, New Directions Educational Society
            (NDES) is dedicated to providing international-quality education and
            training to enhance the employability of Indian youth, enabling them
            to secure gainful employment or succeed in self-employment.
          </p>
        </div>
        <MissionVisionValues />
        <OurAchievements />
        <Affiliations />

        {/* ********************************* accomplishments ************************* */}

        <div className="mx-3 my-4">
          <Row className=" mt-5 lh-lg  py-4">
            <h2
              className="text-center textColor title-styling pb-3 fs-1"
              style={divStyle}
            >
              Accomplishments
            </h2>
            <Col xs={12} md={6} xl={8} data-aos="fade-right">
              <p>
                New Directions Education and Welfare Society, Hyderabad, Regd.
                No. 393/2010, is a distinguished organization registered under
                the Societies Act of 1860. We hold 12A and 80G registrations,
                making us an Income Tax-exempt NGO dedicated to Women
                Empowerment through Skill Development, Entrepreneurship
                Training, and Incubation of enterprises.
                <span
                  style={{ display: isExpanded ? "none" : "inline" }}
                  id="dots"
                >
                  ...
                </span>
                <span
                  style={{ display: isExpanded ? "inline" : "none" }}
                  id="more"
                >
                  {" "}
                  Committed to providing a sustainable way of life for
                  underprivileged and disadvantaged women, we operate under the
                  umbrella of NGO Darpan with a UNIQUE ID: TS/ 2018/0197015.
                  Additionally, we are recognized by the Ministry of Corporate
                  Affairs, GOI, as a CSR Implementing Organization, with Regd.
                  No.: CSR00005204. Smt. Samyuktha Beegala, the co-founder and
                  CEO of ND Skills, a unit of M/s. New Directions and Welfare
                  Society, has led our organization in empowering countless
                  students over the past decade. Our focus on livelihood-based
                  education and skills development aims to nurture their chosen
                  careers for better living and social development.
                </span>
              </p>
              <span
                className="border border-0 text-danger rounded-1 fs-6 fw-medium btn-space"
                onClick={handleToggle}
              >
                {isExpanded ? "Read less" : "Read more..."}
              </span>
            </Col>
            <Col data-aos="fade-left" xs={12} md={6} xl={4}>
              {!imgLoading.award1 && (
                <Blurhash
                  hash="m597CH5EEN},s=xo9b-UNI%KxuIUxS4?%2t2E3xAM}t7s;WBNGt7"
                  height={250}
                  width={400}
                  punch={1}
                  className="rounded img-fluid"
                />
              )}
              {imgLoading.award1 && (
                <img
                  src={award1}
                  alt="awardpic"
                  className=" rounded img-fluid"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade-right"
              xs={12}
              md={6}
              xl={4}
              className="img-distance"
            >
              {" "}
              {!imgLoading.award3 && (
                <Blurhash
                  hash="jiMQI*_NodtRXTxu"
                  height={250}
                  width={350}
                  punch={1}
                  className="img-distance"
                />
              )}
              {imgLoading.award3 && (
                <img
                  src={award3}
                  alt="awardpic"
                  className="rounded img-fluid "
                />
              )}
            </Col>
            <Col xs={12} md={6} xl={8} className="lh-lg " data-aos="fade-left">
              {" "}
              <p>
                {" "}
                Operating in diverse locations such as Telangana (Hyderabad),
                Andhra Pradesh (Visakhapatnam, Kakinada), Kerala (Cochin), and
                internationally in Australia (Melbourne) and the United States
                of America (Boston), we have a global footprint in our pursuit
                of social impact.
                <span
                  style={{ display: isCollapse ? "none" : "inline" }}
                  id="dots"
                >
                  ...
                </span>
                <span
                  style={{ display: isCollapse ? "inline" : "none" }}
                  id="more"
                >
                  In addition to our core initiatives, we are actively involved
                  in migration and healthcare businesses, further expanding our
                  commitment to holistic community development. This
                  multifaceted approach aligns with our mission to bring
                  positive change and sustainable livelihoods to the communities
                  we serve. We are honored to have received the Award of
                  Excellence in April 2023 from the Media and Entertainment
                  Skills Council, recognizing our outstanding
                  contributions to the field.
                </span>
              </p>
              <span
                className="border border-0 text-danger rounded-1 fs-6 fw-medium btn-space"
                onClick={handleOtherToggle}
              >
                {isCollapse ? "Read less" : "Read more..."}
              </span>
            </Col>
          </Row>
          <Row>
            <h2
              className="text-center textColor title-styling pt-5 fs-1"
              style={divStyle}
            >
              Latest News
            </h2>
            <Col className="d-flex align-items-center bg-light py-2">
              <marquee
                width="100%"
                direction="left"
                height="60px"
                scrollamount="15"
                className="d-flex align-items-center"
                onClick={() => {
                  openPdf(pdf);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <img src={newImg} alt="new-img" className="new-img" />
                <span className="text-dark mx-2 fs-5">
                  Dasarath Ram Beegala, founder of NDSkills, appointed as NGO
                  Member of State Advisory Committee (SAC)
                </span>
                <img
                  src={APERCLogo}
                  alt="APERC Logo"
                  className="img-fluid mx-2"
                  style={{ maxHeight: "40px" }}
                />
              </marquee>
            </Col>
          </Row>
        </div>
        <div className="my-5">
          <OurContribution formatter={formatter} />
        </div>
      </div>
    </>
  );
};

export default OurOrganisation;
